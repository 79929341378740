import request from './request'

const api = {
	// 获取列表
	lists: '/gh/Article/getPageList',
	// 添加
	add: '/gh/Article/create',
	// 删除
	dele: '/gh/Article/delete',
	// 编辑
	edit: '/gh/Article/update',
	// 详情
	info: '/gh/Article/read',
	// 分类列表
	newsCate: '/gh/ArticleCate/getPageList',
}

export {
	request,
	api
}
